import { graphql } from "gatsby"
import React, { Fragment, useRef } from "react"
import { Container, Col, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleUp
} from "@fortawesome/free-solid-svg-icons"
import { v4 as uuidv4 } from "uuid"

import { PullQuote } from "../UI/PullQuote"
import { PillButton } from "../UI/PillButton"
import Link from "../UI/Link"
import {
  linkToClass,
  quoteBlockContainerClass,
  quoteClass
} from "./TabDetail.module.scss"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment tabDetailFragment on WpPage_Flexlayouts_FlexibleLayouts_TabDetail {
    paragraph
    details {
      title
      headingType
      paragraph
      image {
        ...GatsbyImageQuery
      }
      quoteText
      quoteAuthor
      linkTo {
        title
        url
      }
    }
  }
`

const TabDetail = ({ paragraph, details }) => {
  const refs = details.reduce((acc, value) => {
    acc[value.title] = React.createRef()
    return acc
  }, {})

  const handleClick = id => {
    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }

  //back to top
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView()
  }

  return (
      <div className={`bg-pale-grey`}>
        <Container className="bg-white pt-5">
          <div className="px-lg-3 px-xl-7" ref={myRef}>
            {paragraph && (
                <p
                    className="quicksand-500"
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                />
            )}
            {details && (
                <div className="mb-5">
                  {details.map(item => {
                    if(item.title) {
                      return (
                          <Fragment key={uuidv4()}>
                            <PillButton
                                title={item.title}
                                classes="me-3 mb-3"
                                onClick={() => handleClick(item.title)}
                            />
                          </Fragment>
                      )
                    }
                  })}
                </div>
            )}
          </div>
          {details && (
              <Row className="flex-column px-lg-3 px-xl-7">
                {details.map(item => {
                  const {
                    title,
                    headingType,
                    paragraph,
                    image,
                    quoteText,
                    quoteAuthor,
                    linkTo
                  } = item

                  const img = getImage(image?.localFile)

                  return (
                      <Col key={uuidv4()} ref={refs[title]}>
                        <Row>
                          {image && (
                              <Col lg={4} className="d-flex justify-content-center">
                                <GatsbyImage
                                    className="feelingImg"
                                    image={img}
                                    alt={image?.altText}
                                />
                              </Col>
                          )}

                          <Col className="d-flex flex-column">
                            <SectionHeading text={title} className="mb-4" type={headingType} />
                            <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                            {quoteText && (
                                <div
                                    className={quoteBlockContainerClass}
                                    role="presentation"
                                    aria-hidden="true">
                                  <div className={quoteClass}>
                                    <PullQuote quote={quoteText} author={quoteAuthor} />
                                  </div>
                                </div>
                            )}
                            {linkTo && (
                                <Link
                                    classes={`linkClass ${linkToClass}`}
                                    link={item.linkTo.url}
                                    title={item.linkTo.title}
                                />
                            )}

                            <br />
                            <div
                                className={`d-flex justify-content-end mt-auto`}
                                onClick={executeScroll}>
                              <FontAwesomeIcon
                                  className="linkClass"
                                  icon={faArrowCircleUp}
                                  size="lg"
                              />
                            </div>
                          </Col>
                          <hr className="bg-violet my-5" style={{ height: "2px" }} />
                        </Row>
                      </Col>
                  )
                })}
              </Row>
          )}
        </Container>
      </div>
  )
}

export default TabDetail
